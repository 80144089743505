import * as AWS from "@aws-sdk/client-ssm";
import { Auth } from "aws-amplify";
import {
  API_GATEWAY_URL,
  DATA_BUCKET_NAME,
  COGNITO_IDENTITYPOOL_ID,
  COGNITO_USERPOOL_ID,
  COGNITO_USERPOOL_WEBCLIENT_ID,
  POTREE_PROXY_ENDPOINT,
  TIMESERIES_ENDPOINT,
  CAL_API_ENDPOINT,
  MAPPING_REQUEST_URL,
  CAL_EFS_API_ENDPOINT,
} from "./domains/shared/constants/constants";
import { makeObjectFromParameters } from "../src/domains/shared/utils/aws-utils";

// parameter store 에서 받아옴
// 최대 10개까지만 받을 수 있어서 api 로 교체
// const { Parameters } = await ssm.getParameters(params, {});

// parameter store 에서 값 가져오는 api

const Parameters = await fetch(process.env.NEXT_PUBLIC_SSM_URL)
  .then((res) => res.json())
  .catch((error) => {
    throw new Error(error);
  });

export const configObject = makeObjectFromParameters(
  Parameters as {
    Name: string;
    Value: string;
  }[]
);

export const amplifyConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: configObject[COGNITO_IDENTITYPOOL_ID],
    // REQUIRED - Amazon Cognito Region
    region: process.env.NEXT_PUBLIC_REGION!,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: configObject[COGNITO_USERPOOL_ID],
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: configObject[COGNITO_USERPOOL_WEBCLIENT_ID],
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  Storage: {
    AWSS3: {
      bucket: configObject[DATA_BUCKET_NAME],
      region: process.env.NEXT_PUBLIC_REGION!,
    },
  },
  API: {
    endpoints: [
      {
        name: "DronesquareApi-PublicStorage",
      },
      {
        name: "DronesquareApi",
        endpoint: configObject[API_GATEWAY_URL],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "DronesquareApi-Timeseries",
        endpoint: configObject[TIMESERIES_ENDPOINT],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "DronesquareApi-Calculation",
        endpoint: configObject[CAL_API_ENDPOINT],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },

      {
        name: "DronesquareApi-Efs-Calculation",
        endpoint: configObject[CAL_EFS_API_ENDPOINT],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "DronesquareApi-Potree",
        endpoint: configObject[POTREE_PROXY_ENDPOINT],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "MyCustomCloudFrontApi",
        endpoint: "https://d12nqhyx53o365.cloudfront.net/api",
      },
      {
        name: "DronesquareApi-Mapping-Request",
        endpoint: configObject[MAPPING_REQUEST_URL],
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  ssr: true,
};
