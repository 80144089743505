import { ReactNode, useMemo } from "react";
import { css } from "@emotion/react";

interface ColumnProps {
  page?: string;
  children: ReactNode[];
}

export default function Column({ page, children }: ColumnProps) {
  const pageStyle = useMemo(() => {
    if (page === "inspection3D") {
      // 2. Tools 카드
      return css`
        > div:nth-child(2) {
          grid-row: 1 / 3;
          grid-column: 2 / 3;
        }
      `;
    }
    if (page === "inspection2D") {
      // 7. HD 이미지 카드
      return css`
        > div:nth-child(7) {
          grid-row: 4 / 6;
          grid-column: 1 / 2;
        }
      `;
    }
  }, [page]);
  return <div css={[gridStyle, pageStyle]}>{children}</div>;
}

const gridStyle = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
`;
