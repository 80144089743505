import { css } from "@emotion/react";

export default function Text({ children }) {
  return <p css={pStyle}>{children}</p>;
}
const pStyle = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.75;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
