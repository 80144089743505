import { css } from "@emotion/react";
import { ReactNode } from "react";

interface ButtonProp {
  appearance?: "primary" | "default";
  children: ReactNode;
}

export default function Button({
  appearance = "primary",
  children,
}: ButtonProp) {
  return (
    <button css={appearance === "primary" ? primaryButton : defaultButton}>
      {children}
    </button>
  );
}

const button = css`
  color: white;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 6px;
  margin: 2px auto;
  vertical-align: middle;
`;

const primaryButton = css`
  ${button};
  background-color: #169de0;
`;

const defaultButton = css`
  ${button};
  background-color: #3c3f43;
`;
