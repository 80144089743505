import { css } from "@emotion/react";
import { ReactNode } from "react";

export default function Section({
  id,
  children,
}: {
  id?: string;
  children: ReactNode;
}) {
  return (
    <div css={container} id={id}>
      {children}
    </div>
  );
}

const container = css`
  margin: 0 auto;
  padding: 50px 0;
`;
