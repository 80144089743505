import { css, Global } from "@emotion/react";
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-openlayers7/dist/index.css";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { COLOR } from "src/domains/shared/constants/style";

const GlobalStyle = () => (
  <Global
    styles={css`
      * {
        font-family: sans-serif;
        letter-spacing: normal;
        line-height: normal;

        margin: 0;
        padding: 0;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: ${COLOR.Gray750};
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: ${COLOR.Gray800};
        }
      }
      body,
      #__next {
        height: 100vh;
        background-color: #1a1d24;
      }

      body.Overflow--hidden {
        overflow-y: hidden;
      }

      .apexcharts-menu {
        color: ${COLOR.Gray800};
      }
      .rs-tree {
        max-height: 79vh;
      }

      .apexcharts-toolbar-custom-icon {
        margin-top: 4px;
      }
      .apexcharts-menu-item.exportCSV {
        display: none;
      }
      .rs-tree-view {
        max-height: calc(100vh - 280px);
      }
      .rs-picker-default .rs-picker-toggle {
        z-index: 0;
      }
      .rs-picker-date-menu {
        z-index: 1000;
      }
      .rs-picker-toggle-value,
      .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: white;
      }
      .rs-btn-block + .rs-btn-block {
        margin-top: 0;
      }
      .none-border .rs-picker-toggle {
        border: none;
        box-shadow: none;
      }
      .thin-slider-progress {
        height: 3px;
        > .rs-slider-progress-bar {
          height: 3px;
        }
        + .rs-slider-handle {
          top: -100%;
        }
      }
      .rs-sidenav-nav .rs-dropdown-item {
        display: flex;
      }
    `}
  />
);

export default GlobalStyle;
