import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { locale } from "src/store/localeStore";

export default function useLocale() {
  const [localeState, setLocaleState] = useRecoilState(locale);

  useEffect(() => {
    const code = localStorage.getItem("ds-locale") as "en" | "ko";
    if (!code) {
      localStorage.setItem("ds-locale", localeState.locale);
      setLocaleState({ locale: localeState.locale });
    } else {
      setLocaleState({ locale: code });
    }
  }, []);

  const changeLocaleState = useCallback(
    ({ locale }: { locale: "en" | "ko" }) => {
      setLocaleState({ locale });
      localStorage.setItem("ds-locale", locale);
    },
    []
  );

  return [localeState, changeLocaleState] as const;
}
