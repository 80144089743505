import { css } from "@emotion/react";
import Title from "./Title";
import Column from "./Column";
import Button from "./Button";
import Text from "./Text";
import Section from "./Section";
import Img from "./Img";
import CardContainer from "./CardContainer";
import CardContent from "./CardContent";
import { COLOR } from "src/domains/shared/constants/style";

const Main = ({ children }) => {
  return (
    <div css={contentStyle}>
      <div>{children}</div>
    </div>
  );
};

const contentStyle = css`
  display: flex;
  justify-content: center;
  min-width: 1280px;
  background: white;
  color: black;
  font-size: 18px;
  padding: 0 50px;
`;

export const linkStyle = css`
  color: ${COLOR.Primary400};
  font-weight: 800;
  cursor: pointer;
  :hover {
    color: ${COLOR.Primary300};
  }
`;

export const highlightStyle = css`
  color: ${COLOR.Gray650};
  font-weight: 800;
`;

Main.Title = Title;
Main.Column = Column;
Main.Button = Button;
Main.Text = Text;
Main.Section = Section;
Main.Img = Img;
Main.CardContainer = CardContainer;
Main.CardContent = CardContent;

export default Main;
