import { css } from "@emotion/react";
import { Fragment } from "react";
import Main from "src/domains/manual/component/Main";
import { COLOR } from "../constants/style";

type LinkText = {
  type: "link";
  link: string;
  text: string;
  lineEnd: boolean;
};

type BoldText = {
  type: "bold";
  text: string;
  lineEnd: boolean;
};

type ButtonText = {
  type: "button";
  text: string;
  lineEnd: boolean;
};

type ColorText = {
  type: "color";
  color: string;
  text: string;
  lineEnd: boolean;
};
type Text = {
  type: "text";
  text: string;
  lineEnd: boolean;
};

export type TextParserArg = (
  | LinkText
  | BoldText
  | ButtonText
  | ColorText
  | Text
)[];

export const linkStyle = css`
  color: ${COLOR.Primary400};
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  :hover {
    color: ${COLOR.Primary300};
  }
`;

export const textParser = (texts: any) => {
  const parsedText = texts.map((text, index) => {
    if (text.type === "text") {
      return (
        <Fragment key={index}>
          {text.text}

          {text.lineEnd && <br />}
        </Fragment>
      );
    }
    if (text.type === "link") {
      return (
        <Fragment key={index}>
          <a css={linkStyle} href={text.link}>
            {text.text}
          </a>
          {text.lineEnd && <br />}
        </Fragment>
      );
    }
    if (text.type === "bold") {
      return (
        <Fragment key={index}>
          <b
            css={css`
              font-size: 18px;
            `}
          >
            {" "}
            {text.text}
          </b>
          {text.lineEnd && <br />}
        </Fragment>
      );
    }
    if (text.type === "button") {
      return (
        <Fragment key={index}>
          <Main.Button>{text.text}</Main.Button>&nbsp;
          {text.lineEnd && <br />}
        </Fragment>
      );
    }

    if (text.type === "color") {
      return (
        <Fragment key={index}>
          <span
            css={css`
              font-size: 18px;
              color: ${text.color};
            `}
          >
            {text.text}
          </span>
          {text.lineEnd && <br />}
        </Fragment>
      );
    }
  });

  return <>{parsedText}</>;
};
