import { css } from "@emotion/react";
import { ReactNode, useId } from "react";
import { COLOR } from "src/domains/shared/constants/style";

interface TitleProps {
  tag: "h1" | "h2" | "h3";
  children: ReactNode;
}

const Title = ({ tag, children }: TitleProps) => {
  if (tag === "h1") return <h1 css={h1Style}>{children}</h1>;
  if (tag === "h2") return <h2 css={h2Style}>{children}</h2>;
  if (tag === "h3") return <h3 css={h3Style}>{children}</h3>;
};

const h1Style = css`
  font-size: 42px;
  color: ${COLOR.Primary400};
  margin-bottom: 45px;
  line-height: 60px;
`;

const h2Style = css`
  font-size: 36px;
  margin-bottom: 45px;
  ::before {
    content: "◼ ";
    width: 30px;
    height: 30px;
    color: ${COLOR.Primary400};
  }
`;

const h3Style = css`
  font-size: 30px;
  margin: 0 0 50px 50px;
`;

export default Title;
