type ModalCustomProps = {
  width: string;
  height: string;
};

const getModalCustomStyles = (styles: ModalCustomProps) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margnRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: styles?.width,
    height: styles?.height,
    padding: 0,
    backgroundColor: "#1A1D24",
    overflow: "hidden",
    borderRadius: "10px",
  },
  overlay: { zIndex: 1000 },
});

export default getModalCustomStyles;
