import ReactModal from "react-modal";
import { css } from "@emotion/react";
import Button from "rsuite/Button";
import getModalCustomStyles from "src/domains/shared/utils/getModalCustomStyles";
import { useModal } from "../../hooks/useModal";
import { ComponentType } from "react";
import useLocale from "../../hooks/localeHook/useLocale";
import { localeString } from "../../constants/locale";

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal["props"]>;

const CustomModal = () => {
  const { isOpen, style, children, isLoading, callback, closeModal } =
    useModal();
  const [locale, setLocale] = useLocale();

  return (
    <ModalSafeForReact18
      isOpen={isOpen}
      ariaHideApp={false}
      style={getModalCustomStyles(style)}
      onRequestClose={closeModal}
      contentLabel="Custom Modal"
    >
      <div>{children}</div>
      {callback && (
        <div css={buttonContainer}>
          <Button onClick={closeModal}>
            {localeString.constants.cancel[locale.locale]}
          </Button>
          <Button
            type="submit"
            appearance="primary"
            disabled={isLoading}
            css={submitButton}
            onClick={callback}
          >
            {localeString.constants.check[locale.locale]}
          </Button>
        </div>
      )}
    </ModalSafeForReact18>
  );
};

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const submitButton = css`
  margin-left: 10px;
  margin-right: 10px;
`;

export default CustomModal;
