import { ReactNode, useId } from "react";
import { css } from "@emotion/react";

interface CardProps {
  id?: string;
  isGray?: boolean;
  children: ReactNode;
}

function CardContainer({ id, isGray = false, children }: CardProps) {
  const combinedId = useId();
  return (
    <div id={id ? id : combinedId} css={isGray ? [grayStyle, style] : style}>
      {children}
    </div>
  );
}

const style = css`
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 680px;
`;

const grayStyle = css`
  background-color: #f0f3f5;
`;

export default CardContainer;
