export const COLOR = {
  Primary50: "#5A89FF",
  Primary100: "#3C6EEB",
  Primary200: "#2853BF",
  Primary300: "#24b3f5",
  Primary400: "#087AD1",
  Primary500: "#34C3FF",
  White100: "#fff",

  Gray100: "#F7F7F7",
  Gray200: "#E5E6EC",
  Gray300: "#CBCDD6",
  Gray400: "#BDBEC6",
  Gray500: "#A2A5AD",
  Gray550: "#7f7d7d",
  Gray600: "#8C8F9C",
  Gray650: "#676A75",
  Gray700: "#585B67",
  Gray750: "#464851",
  Gray800: "#303136",
  Gray850: "#212225",
  Gray900: "#191A1C",
  Gray950: "#111111",
  Gray1000: "#1a1d24",
  Gray: "#38393F",
  Red100: "#EB3C3C",
} as const;

export const MATERIAL_DESIGN_COLOR30 = [
  "#F44336",
  "#FF8A65",
  "#FFC107",
  "#8BC34A",
  "#00BCD4",
  "#2196F3",
  "#3F51B5",
  "#673AB7",
  "#9C27B0",
  "#F06292",
  "#FF9800",
  "#FFD54F",
  "#AED581",
  "#4DB6AC",
  "#64B5F6",
  "#9575CD",
  "#E57373",
  "#FFB74D",
  "#FFECB3",
  "#D4E157",
  "#81C784",
  "#4CAF50",
  "#009688",
  "#03A9F4",
  "#BA68C8",
  "#FF5722",
  "#CDDC39",
  "#4DD0E1",
  "#7986CB",
];

export const FONT_SIZE = {
  xLarge: "28px",
  xsLarge: "24px",
  xxsLarge: "20px",
  Large: "16px",
  medium: "14px",
  small: "12px",
} as const;

export const BUTTON_COLOR = {
  Default: COLOR.Gray750,
  Primary: COLOR.Primary300,
};

export const NAV = {
  width: "340px",
  iconWidth: "56px",
  bg: COLOR.Gray800,
  padding: "18px",
};

export const TABLE = {
  ROW_HEIGHT: 46,
};

export type ColorType = keyof typeof COLOR;

export type FontSizeType = keyof typeof FONT_SIZE;

export type ButtonColorType = keyof typeof BUTTON_COLOR;
