import { ReactNode } from "react";
import { css } from "@emotion/react";

interface CardContentProps {
  isMargin?: boolean;
  children: ReactNode;
}

function CardContent({ isMargin = true, children }: CardContentProps) {
  const style = css`
    margin: ${isMargin ? "50px" : "0 50px 50px"};
    p {
      line-height: 1.75;
      font-weight: 500;
    }
  `;

  return <div css={style}>{children}</div>;
}

export default CardContent;
