import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { modal } from "src/store/modalStore";

export const useModal = () => {
  const [modalState, setModalState] = useRecoilState(modal);

  const closeModal = useCallback(() => {
    setModalState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, [setModalState]);

  const enableModal = useCallback(() => {
    setModalState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  }, [setModalState]);

  const disableModal = useCallback(() => {
    setModalState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  }, [setModalState]);

  const openModal = useCallback(
    ({ children, callback, style }) => {
      setModalState({
        isOpen: true,
        callback,
        style,
        children,
      });
    },
    [setModalState]
  );

  return {
    ...modalState,
    closeModal,
    enableModal,
    disableModal,
    openModal,
  };
};
