import { MouseEvent } from "react";
import { atom } from "recoil";
import { v1 } from "uuid";

export type Modal = {
  isOpen: boolean;
  callback?: (e: MouseEvent<HTMLButtonElement>) => void;
  style: { width: string; height: string };
  isLoading?: boolean;
  children: JSX.Element | string;
};

export const modal = atom<Modal>({
  key: `codeKey/${v1()}`,
  default: {
    isOpen: false,
    callback: null,
    style: { width: "100%", height: "100%" },
    isLoading: false,
    children: null,
  },
});
