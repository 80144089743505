import { css } from "@emotion/react";
import Image, { ImageProps } from "next/image";

type ImgProps = ImageProps & {
  width: number;
  height: number;
  isSection?: boolean;
  isIcon?: boolean;
  caption?: string;
  alt?: string;
};

export default function Img({
  width,
  height,
  isSection = false,
  isIcon = false,
  caption,
  alt = "image",
  ...args
}: ImgProps) {
  const imageBox = css`
    position: relative;
    width: ${width}px;
    height: ${height}px;
    margin: ${isSection ? 0 : isIcon ? `0 10px 0 0` : `0 auto`};
  `;
  return (
    <>
      <div css={imageBox}>
        <Image
          placeholder="blur"
          alt={alt}
          layout="fill"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg=="
          {...args}
        />
      </div>
      {caption && <p css={captionStyle}>{caption}</p>}
    </>
  );
}
const captionStyle = css`
  margin-top: -80px;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
`;
